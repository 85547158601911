import React from "react"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"

import SEO from "../components/SEO"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import data from "../assets/data.json"
import useImageData from "../utils/useImageData"



const ProductEngineeringPage = () => {
    const pageData = data.categories.find(item => item.title === "Product Engineering")
    const imageData = useImageData()
    
    return (
        <>
            <SEO title="Product Engineering" />
            <Navigation />
            <Toolbar />
            <Box mt={16}>
                <Container maxWidth="md">
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h1" align="center" gutterBottom>
                            Product Engineering
                        </Typography>
                        <Typography variant="body1" align="center" color="textSecondary">
                            { pageData.description }
                        </Typography>
                        <Toolbar />
                        <Button variant="contained" color="primary" href="/contact">
                            Get Started
                        </Button>
                    </Box>

                    { pageData.projects.map((project, i) => (
                        <Box mt={12} key={i}>
                            <Divider />
                            <Box pt={2} pb={2} mt={2}>
                                <Typography variant="h2" gutterBottom>
                                    { project.title }
                                </Typography>
                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                    { project.description }
                                </Typography>
                                <Box mt={8}>
                                <GridList cols={2} cellHeight={400} spacing={32}>
                                    { project.images.map(image => (
                                        <GridListTile key={ image }>
                                            <Img 
                                                fixed={ imageData[image]?.childImageSharp.fixed } 
                                                alt={ image }
                                                objectFit="cover"
                                            />
                                        </GridListTile>
                                    ))}
                                </GridList>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Container>
            </Box>
            <Footer />
        </>
    )
}

export default ProductEngineeringPage
